<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/logo_new_white.png" alt="logo"></a>
                    <p style="margin-top:-10px">
                        L'Accademia Ilas® è specializzata nella formazione nell'ambito della comunicazione pubblicitaria e visiva. Ilas è una struttura privata tra le più prestigiose in Italia: L'Accademia Ilas®, infatti, garantisce una formazione accurata e controllata in tutte le sue fasi.
                    </p>
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/ilascentrostudi" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://www.instagram.com/ilasacademy/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="https://www.linkedin.com/company/10946992" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="http://www.youtube.com/ilascom" class="d-block" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Link</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/elenco-corsi">Elenco Corsi</a></li>
                        <li><a routerLink="/validita-attestati">Validità Attestato</a></li>
                        <li><a routerLink="/contatti">Contatti</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Login</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Discenti</a></li>
                        <li><a href="https://discentya.ilas-formazione.it/login.php" target="_blank">Docenti</a></li>
                        <li><a href="https://discentya.ilas-formazione.it/login.php" target="_blank">Partners</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Dove Siamo</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>Via Alcide De Gasperi,45 - Scala A<br>80133 Napoli (NA)</li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:+390815511353">Telefono: (+39) 081-5511353</a><br><a href="tel:+390815511353">Telefono: (+39) 081-4201345</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:segreteria@istitutoilas.com">segreteria@istitutoilas.com</a></li>
                        <li><i class='bx bxs-inbox'></i><a href="tel:+557854578964">Lunedì-Venerdì:<br>9:00 – 13:00 / 14:30 – 18:00</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-8">
                    <p><i class='bx bx-copyright'></i>2024 ILAS® - realizzato da <a target="_blank" href="https://www.discentya.it/" style="color: #feee21;">Discentya</a>, piattaforma per la formazione online</p>
                </div>
                <div class="col-lg-2 col-md-2 d-none">
                    <ul>
                        <li><a href="https://www.ilas.com/" target="_blank">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
